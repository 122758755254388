<template>
    <div :class="$style.container" v-if="show">
         <svg @click="$emit('closeTest')" :class="$style.closeIcon" style="cursor: pointer" width="44" height="44" viewBox="0 0 44 44" fill="#fff" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="22" fill="#16161D" fill-opacity="1"/>
            <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="none" stroke-opacity="1"/>
            <rect width="2.25" height="20.25" rx="1.125" transform="matrix(0.707107 0.707107 -0.707099 0.707115 28.3643 14.0452)" fill="#000" stroke="#000"/>
            <rect width="2.25" height="20.25" rx="1.125" transform="matrix(-0.707107 0.707107 -0.706747 -0.707466 29.9551 28.3638)" fill="#000" stroke="#000"/>
        </svg>
        <iframe :class="$style.iframe" :src="src" rel="preload"/>
    </div>
</template>

<script>
export default {
  name: 'PersonalityTest',
  props: {
    show: Boolean,
  },
  data() {
    return {
      src: process.env.VUE_APP_CHATGPT_BRIDGE_SOURCE,
    };
  },
};
</script>

<style module>
    .container {
        width: 100vw;
        height: 100vh;
        z-index: 999;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.85);
    }

    .iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .closeIcon {
        top: 20px;
        right: 40px;
        position: absolute;
        z-index: 1000;
    }
</style>
