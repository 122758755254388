<template>
  <Layout :loading="loading">

    <Loader :class="$style.loader" v-if="!article || loading" />
    <template v-else>
      <div :class="{ [$style.grid]: !mobile}" :style="{paddingTop: mobile ? '50px' : '100px'}">
        <div
          :class="$style.imageContainer"
          :style="{ height: mobile ? '410px' : '627px' }"
        >
          <div :class="$style.image" :style="{ aspectRatio: '1 !important' }">
            <div :class="$style.img" :style="{ backgroundImage: `url(${isLoaded && !optimizedImageError ? article.image: article.image_optimized ?? article.image})` }">
              <img
                :src="article.image_optimized"
                style="display: none"
                @error="handleImageError"
                @load="loaded"
              />
            </div>
              <OnProductPDP :entry="{ item: article.alias }" v-if="!mobile"/>
          </div>
        </div>

        <main :class="{ [$style.body]: mobile, [$style.bodyDesktop]: !mobile }" :style="{ marginTop: mobile ? '-80px': '' }">
            <div style="display: flex; justify-content: center; padding-top: 24px; margin-bottom: -8px;" v-if="mobile">
                <CTAButton
                    text="Discover Similar Style"
                    style="margin-bottom: 24.37px; z-index: 2"
                    v-if="!loading"
                    image-height="55"
                />
            </div>
            <div :class="[$style.description, 'monster']" :style="{ marginBottom: mobile ? '-30px': '', marginTop: mobile ? '24.37px' : ''}">
              <div>{{ currency }}{{ article.price }}</div>
              <div style="display: flex; justify-content: center">
                  <div :class="$style.name">{{ article.name }}</div>
              </div>
          </div>
          <div :class="$style.feel" :style="{paddingTop: mobile ? '24px' : '38px'}">
              <CTAButton
                  text="Discover Similar Style"
                  style="background: #16161D; color: #fff; height: 38px; width: 100%"
                  v-if="!loading && !mobile"
                  image-height="55"
              />
              <div @click="isUrlValid ? gotoURL() : null" :class="$style.goto" :style="{border: isUrlValid ? '2px solid #16161D' : '2px solid #D0D0D2', cursor: isUrlValid ? 'pointer': '', color: isUrlValid ? '#16161D' : '#D0D0D2', marginTop: mobile ? '24px' : '27.5px'}">
                  GO TO ORIGINAL PRODUCT
              </div>
              <div :class="$style.prodInfo">
                  <div :class="$style.prodText">SIZE</div>
                  <div :class="$style.prodText">product details</div>
                  <div :class="$style.prodText">Delivery & returns info</div>
                  <div :class="$style.prodText">Reviews</div>
              </div>
          </div>
        </main>
      </div>
        <div :class="{[$style.desktop]: !mobile}" :style="{margin: mobile ? '0 12px': 'auto', maxWidth: '1350px'}">
          <div data-miros-entry="inline" data-miros-lang="en" :data-miros-item="article.alias" />
        </div>
    </template>
  </Layout>
</template>

<script>
import Loader from '@/components/Loader';
import { getArticleByAlias } from '@/api';
import { OnProductPDP, AfterDetailsPDP } from '@/components/entrypoints';
import { isMobile } from '@/util';
import imageOptimized from '@/mixins/imageOptimized';
import CTAButton from '@/components/CTAButton.vue';

export default {
  components: {
    CTAButton,
    Loader,
    OnProductPDP,
  },
  mixins: [imageOptimized],
  data() {
    return {
      loading: true,
      article: null,
    };
  },
  computed: {
    mobile() {
      return isMobile();
    },
    currency() {
      return window.CURRENCY;
    },
    isUrlValid() {
      return this.article.url && !this.article.url.includes('find.fashion');
    },
  },
  watch: {
    '$route.params.alias': function () {
      this.fetchArticle();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchArticle();
  },
  methods: {
    async fetchArticle() {
      const { alias } = this.$route.params;
      this.loading = true;
      this.article = await getArticleByAlias(alias);
      this.loading = false;

      if (!this.article) {
        this.$router.replace({ path: '/404' });
      }
    },
    gotoURL() {
      window.location.href = this.article.url;
    },
    openFF(e) {
      e.stopPropagation();
      e.preventDefault();

      if (!this.pool && !(this.$route.params.item || this.article.alias)) {
        if (window.SHOP_POOL) {
          window.showFF({ pool_id: window.SHOP_POOL });
        }
      } else {
        window.showFF({
          pool: this.pool,
          item: this.$route.params.item || this.article.alias,
        });
      }
    },
  },
};
</script>

<style module>
.loader {
  /*margin: 4rem auto 0;*/
    width: 100%;
    padding-top: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.imageContainer {
  padding-top: 120%;
  position: relative;
}
.image {
  position: absolute;
  top: 0;
  /*left: 0;*/
  right: 0;
  bottom: 0;
  height: 83%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1 !important;
}

.img {
    position: relative;
    background-color: #f3f3f4;
    cursor: pointer;
    aspect-ratio: 1;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.description > div{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #8A8A8E;

}
.body {
  margin: 0.625rem 24px 74px;
}

.bodyDesktop {
  margin-left: 44px;
    padding-top: 40px;
}
.feel {
  width: 100%;
}

.feel svg {
  width: 100%;
  height: 100%;
}

.name {
    text-align: center;
    width: 300px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8A8A8E;
}

.grid {
  max-width: 972px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
}

.desktop {
    padding-top: 113px;
    margin-bottom: 100px;
    padding-right: 60px;
    padding-left: 60px;
}

.goto{
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #16161D;
}

.prodInfo{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #D0D0D2;
    padding-top: 3.5px;
}

.prodInfo div {
    padding-top: 23.5px;
    padding-bottom: 23.5px;
    padding-left: 15px;
    border-bottom: 1px solid #D0D0D2;
}

.prodText{
    /*margin-bottom: 23.5px;*/
}

</style>
