export default {
  data() {
    return {
      isLoaded: false,
      optimizedImageError: false,
    };
  },
  methods: {
    handleImageError() {
      this.optimizedImageError = true;
    },
    loaded() {
      return (this.isLoaded = true);
    },
  },
};
