<template>
  <div :style="{ width: '90vw', marginTop: '50px', padding: '0 20px' }">
    <div data-miros-entry="search" :data-miros-lang="lang" :data-miros-demo-pool="pool"></div>
  </div>
</template>
<script>
export default {
  name: 'SearchButton',
  props: {},
  computed: {
    pool() {
      return window.SHOP_POOL;
    },
    lang() {
      return new URLSearchParams(window.location.search).get('lang') ?? 'en';
    },
  },
  methods: {},
};
</script>
