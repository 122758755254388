import { createApp } from 'vue';
import VueGtag from 'vue-gtag-next';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronUp, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import router from '@/router';
import DefaultLayout from '@/layouts/Default.vue';
import App from './App.vue';

library.add(faChevronUp, faMagnifyingGlass);

createApp(App)
  .use(router)
  .use(VueGtag, {
    property: {
      id: 'G-KRNMY04M0G',
    },
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Layout', DefaultLayout)
  .mount('#app');
