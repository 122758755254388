<template>
  <div :class="$style.container">
    <p :class="$style.tutorial_heading">To open FIND.Fashion <br/>
      use buttons called <span :class="$style.highlight">“Entry Points”</span>
    </p>
    <div :class="$style.tutorial_img">
      <img src="/tutorial-2.svg" />
    </div>
    <CTAButton text="Next" @click="goTo" :class="$style.tutorial_next"/>

  </div>
</template>
<script>
import CTAButton from '@/components/CTAButton';

export default {
  name: 'Step2',
  components: {
    CTAButton,
  },
  methods: {
    goTo() {
      this.$router.push({name: 'Listing'});
    },
  },
};
</script>
<style module>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tutorial_heading{
  text-align: center;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.75rem;
}
.highlight {
  border-bottom: 2px solid #F46224;
  font-weight: 700;
  font-style: italic;
}
.tutorial_img {
  margin-bottom: 5rem;
}
.tutorial_next{
  position: sticky;
  bottom: 2.5rem;
}
</style>
