<template>
  <div
      data-miros-entry="product_pdp_demo"
      data-miros-lang="en"
      :data-miros-item="entry.item"
  >
  </div>
</template>

<script>
import { isMobile } from '@/util';

export default {
  name: 'OnProductPDP',
  props: {
    entry: Object,
  },
  computed: {
    mobile() {
      return isMobile();
    },
  },
  methods: {
    goToTutorialStep3(e) {
      e.stopPropagation();
      e.preventDefault();

      if (!this.pool && !(this.$route.params.item || this.entry.item)) {
        if (window.SHOP_POOL) {
          window.showFF({ pool_id: window.SHOP_POOL });
        }
      } else {
        window.showFF({
          pool: this.pool,
          item: this.$route.params.item || this.entry.item,
        });
      }
    },
  },
};
</script>

<style module>
.wrapper {
    padding-top: 17.49px;
    width: 100%;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.textWrapper {
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #16161D;
}
.icon {
    height: 38px;
    width: 38px;
    margin-right: 6px;
}
</style>
