import { createWebHistory, createRouter } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import Tutorial from '@/pages/tutorial/index.vue';
import Step1 from '@/pages/tutorial/Step1.vue';
import Step2 from '@/pages/tutorial/Step2.vue';
import Step3 from '@/pages/tutorial/Step3.vue';
import Articles from '@/pages/articles/index.vue';
import Details from '@/pages/articles/[alias].vue';
// import NotFound from "@/pages/404.vue";

import { fetchCustomerId, getDemoByName } from '@/api';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:customer/',
      name: 'Articles',
      component: Articles,
    },
    {
      path: '/:customer/:alias',
      name: 'Details',
      component: Details,
      props: true,
    },
    {
      path: '/:customer/tutorial/',
      name: 'Tutorial',
      component: Tutorial,
      children: [{
        path: '1',
        name: 'Step1',
        component: Step1,
      }, {
        path: '2',
        name: 'Step2',
        component: Step2,
      }, {
        path: '3',
        name: 'Step3',
        component: Step3,
      }],
    },
    {
      path: '/:customer/articles',
      redirect: '/:customer/',
    },
    {
      path: '/:customer/articles/:alias',
      redirect: '/:customer/:alias',
    },
  ],
});

// Track page views with Vue-GTAG
trackRouter(router);

let initialized = false;

router.beforeEach(async (to, from, next) => {
  if (to.params.customer && !window.SHOP_ALIAS) {
    const customer = await getDemoByName({ slug: to.params.customer });
    const customer_id = await fetchCustomerId(customer);

    try {
      if (document.location.pathname.split('/')[1] !== localStorage.getItem('last-customer')) {
        localStorage.clear();
      }
      localStorage.setItem('last-customer', to.params.customer);
      // eslint-disable-next-line
    } catch { }

    window.SHOP_POOL = customer.pool_id;
    window.SHOP_ITEM = customer.item;
    if (customer.engine) {
      window.ENGINE_OVERRIDE = customer.engine;
    } // picked up by connect
    window.CURRENCY = customer.currency || '€';
    window.DEMO_TITLE = customer.demo_title;
    window.ITEM_COUNT = customer.pool_item_count;
    if (!initialized && window.initFF) {
      window.initFF({
        alias: 'demo.find.fashion',
        hostname: 'demo.find.fashion',
        customer_id,
        customDetailsPath: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${customer.slug}`,
        api: {
          query: {
            pool: window.SHOP_POOL,
          },
        },
        styles: {
          pricing: {
            currency: window.CURRENCY,
          },
        },
      });
      initialized = true;
    }
    if (!window.CUSTOMER_ID) {
      window.CUSTOMER_ID = customer_id;
    }
  } else if (to.path === '/') {
    location.replace('https://find.fashion');
  }

  next();
});

router.beforeEach((to) => {
  const lang = to.query.lang;
  if (lang && typeof lang === 'string') {
    sessionStorage.setItem('lang', lang);
    return;
  }
  if (lang === '') {
    sessionStorage.removeItem('lang');
    delete to.query.lang;
    return to;
  }
  // from storage to url
  const fromStorage = sessionStorage.getItem('lang');
  if (fromStorage) {
    to.query.lang = fromStorage;
    return to;
  }
});

export default router;
