<template>
  <div :class="$style['tutorial-wrapper']">
    <Header />
    <router-view></router-view>
  </div>
</template>
<script>
import Header from '../../components/Header.vue';

export default {
  name: 'Tutorial',
  components: {
    Header,
  },
};
</script>
<style module>
  /*noinspection CssUnusedSymbol*/
  .tutorial-wrapper{
    height: 85vh;
    overflow: hidden;
  }
</style>
