<template>
  <div :class="$style.container">
    <p :class="$style.tutorial_heading">
      In FIND.Fashion tap on the images<br />
      to see recommendations
    </p>
    <div :class="$style.tutorial_img">
      <img src="/tutorial-3.svg" />
    </div>
      <CTAButton
        text="Start Exploring"
        @click="goTo"
        :class="$style.tutorial_next"
      />
  </div>
</template>
<script>
import CTAButton from '@/components/CTAButton';

export default {
  name: 'Step3',
  computed: {
    item() {
      return this.$route.params.item;
    },
    pool() {
      return this.$route.params.pool;
    },
  },
  components: {
    CTAButton,
  },
  methods: {
    goTo(e) {
      e.stopPropagation();
      e.preventDefault();
      // @HACK
      if (!this.pool && !this.item) {
        if (window.SHOP_POOL) {
          window.showFF({ pool_id: window.SHOP_POOL });
        }
      } else {
        window.showFF({pool: this.pool, item: this.item});
      }
      // setTimeout(() => {
      this.$router.go(-1);
      // });
    },
  },
};
</script>
<style module>
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tutorial_heading{
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
  }
  .tutorial_img{
    margin-top: 2.55rem;
  }
  .tutorial_next{
    margin-top: 6rem;
    position: sticky;
    bottom: 2.5rem;
  }
</style>
