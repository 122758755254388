<template>
  <div :class="$style.wrap">
    <p :class="$style.tutorial_heading">
      FIND.Fashion is integrated <br />
      into Your online store
    </p>
    <div :class="$style.tutorial_img">
      <img src="/tutorial-1.svg" />
    </div>
    <CTAButton text="Next" @click="goTo" :class="$style.tutorial_next"/>
  </div>
</template>
<script>
import CTAButton from '@/components/CTAButton';

export default {
  name: 'Step1',
  components: {
    CTAButton,
  },
  methods: {
    goTo() {
      this.$router.push({name: 'Step2'});
    },
  },
};
</script>
<style module>
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tutorial_heading{
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}
.tutorial_img{
  margin-top: 1.4375rem;
  margin-bottom: .5rem;
}
.tutorial_next{
  margin-top: 3.5rem;
  position: sticky;
  bottom: 2.5rem;
}
</style>
