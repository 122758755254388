<template>
  <div :style="overlayStyle" tabindex="2" aria-modal="true" role="dialog">
    <div :class="$style.overlayTextWrapper">
      <svg id="Layer_1" height="39.39" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 95">
        <defs></defs>
        <rect fill="#fff" x="170" y="3.5" width="29" height="88" />
        <path fill="#fff"
          d="M368.11,23.81c-3.93-6.82-9.51-12.07-16.74-15.77-7.23-3.69-15.69-5.54-25.37-5.54s-18.14,1.85-25.37,5.54c-7.23,3.69-12.81,8.95-16.74,15.77-3.93,6.82-5.89,14.72-5.89,23.69s1.96,16.88,5.89,23.69c3.93,6.82,9.51,12.08,16.74,15.77,7.23,3.69,15.69,5.54,25.37,5.54s18.13-1.84,25.37-5.54c7.23-3.69,12.81-8.95,16.74-15.77,3.93-6.82,5.89-14.72,5.89-23.69s-1.96-16.88-5.89-23.69Zm-28.17,39.81c-3.38,3.92-8.02,5.89-13.93,5.89s-10.56-1.96-13.93-5.89c-3.38-3.92-5.07-9.13-5.07-15.61s1.69-11.69,5.07-15.61c3.38-3.92,8.02-5.89,13.93-5.89s10.55,1.96,13.93,5.89c3.38,3.92,5.07,9.13,5.07,15.61s-1.69,11.69-5.07,15.61Z" />
        <path fill="#fff"
          d="M407.31,62.1c.56,3.17,2.29,5.72,5.17,7.64,2.88,1.93,6.47,2.89,10.77,2.89,4.52,0,8.03-.62,10.52-1.87,2.49-1.24,3.73-3.17,3.73-5.77,0-1.58-.54-2.77-1.61-3.57-1.08-.79-2.77-1.39-5.09-1.78-2.32-.4-6.36-.99-12.13-1.78-12.1-1.47-21.29-4.02-27.56-7.64-6.28-3.62-9.41-9.57-9.41-17.83,0-9.06,3.59-16.3,10.77-21.74,7.18-5.43,16.76-8.15,28.75-8.15,12.78,0,23.12,2.58,31.04,7.73,7.91,5.15,12.1,12.14,12.55,20.97h-27.48c-.23-2.6-1.7-4.73-4.41-6.37-2.71-1.64-6.22-2.46-10.52-2.46-3.96,0-7.07,.62-9.33,1.87-2.26,1.25-3.39,3.06-3.39,5.43,0,1.93,1.13,3.26,3.39,3.99,2.26,.74,6.39,1.39,12.38,1.95l3.39,.34c11.76,1.25,20.89,3.77,27.39,7.56,6.5,3.79,9.75,10.27,9.75,19.44,0,6-1.75,11.21-5.26,15.62-3.51,4.42-8.45,7.84-14.84,10.27-6.39,2.44-13.82,3.65-22.31,3.65-12.67,0-22.96-2.74-30.87-8.24-7.92-5.49-12.16-12.88-12.72-22.16h27.31Z" />
        <path fill="#fff"
          d="M117,3.5h-14c-8.87,0-17.03,3.05-23.5,8.15-6.47-5.1-14.63-8.15-23.5-8.15h-14C21.01,3.5,4,20.51,4,41.5v50h29V40.5c0-7.73,6.27-14,14-14h4c7.73,0,14,6.27,14,14v51h29V40.5c0-7.73,6.27-14,14-14h4c7.73,0,14,6.27,14,14v51h29V41.5c0-20.99-17.01-38-38-38Z" />
        <path fill="#fff" d="M251,3.5c-20.99,0-38,17.01-38,38v50h29V40.5c0-7.73,6.27-14,14-14h18V3.5h-23Z" />
      </svg>
      <p style="width: 320px; padding-top: 84px;">Experience Miros' Wordless Search demo with actual items from your
        online store.</p>
      <div style="padding-top: 65.65px">
        <p style="width: 320px; margin-top: 20px">Click Miros button to try it out:
        </p>
        <StartDemoButton text="Start Miros Demo"
                   style="height: 40px;background: #fff; color: #16161D;margin-left: 25px; margin-top: 26px;"
                   v-if="!loading" @click="closeOverlay" image-height="64" />
      </div>
    </div>
  </div>
  <Layout :loading="loading">
    <div :style="{ paddingBottom: mobile ? '34px' : '44px', paddingTop: '49px' }">
      <h1 :class="$style.title">{{ title }}</h1>
      <span :class="$style.personalised" v-if="isPersonalityTestActive">Your <b>Personalised</b> Edit </span>
      <div :class="$style.pagiTop" :style="{ paddingTop: mobile ? '34px' : '44px' }">
        <div>
          <CTAButton text="Use Discovery Mode" :style="mobile
            ? { height: '38px' }
            : { height: '40px' }
            " style="background: #16161D; color: #fff; margin-top: 24px" :exploreBtn="true" v-if="!loading"
            image-height="55"
          />
          <SearchButton />
        </div>
      </div>
    </div>
    <Loader :class="$style.loader" v-if="recommendations.length === 0 || loading" />
    <div v-else :class="$style.wrap">
      <main :class="mobile ? $style.columns_mobile : $style.columns">
        <template :key="recommendation.id" v-for="recommendation in getRecommendations">
          <router-link v-if="recommendation.alias" :to="{ name: 'Details', params: { alias: recommendation.alias } }">
            <div :class="$style.image">
              <img :src="recommendation.image_optimized
                ? recommendation.image_optimized
                : recommendation.image
                " :style="{ width: '100%', objectFit: 'contain', height: '100%' }" :class="$style.img" />
            </div>
            <component v-if="recommendation.entry &&
              recommendation.entry.name === 'OnProductPLP'
              " :is="recommendation.entry.component" :entry="recommendation.entry" />
            <div :class="[$style.description]">
              <p>
                {{ currency }}{{ recommendation.price }}
              </p>
              <div style="display: flex; justify-content: center">
                <p :class="$style.name">
                  {{ recommendation.name }}
                </p>
              </div>
            </div>
            <component v-if="recommendation.entry &&
              recommendation.entry.name !== 'OnProductPLP'
              " :is="recommendation.entry.component" :entry="recommendation.entry" />
          </router-link>
        </template>
      </main>
    </div>
  </Layout>
</template>

<script>
import { getArticles, getArticlesGraphQl, getRecommendationsByNLP } from '@/api';
import { isMobile } from '@/util';
import { OnProductPLP } from '@/components/entrypoints';
import Loader from '@/components/Loader';
import CTAButton from '@/components/CTAButton';
import PersonalityTest from '@/components/PersonalityTest.vue';
import StartDemoButton from '@/components/StartDemoButton.vue';
import SearchButton from '@/components/SearchButton.vue';

export default {
  components: {
    Loader, CTAButton, PersonalityTest, StartDemoButton, SearchButton,
  },
  data() {
    return {
      recommendations: [],
      pool: window.SHOP_POOL,
      currency: window.CURRENCY,
      title: window.DEMO_TITLE,
      loading: true,
      noOfItems: 180,
      opacity: 0,
      overlayDisplay: 'none',
    };
  },

  computed: {
    getRecommendations() {
      return this.recommendations;
    },
    mobile() {
      return isMobile();
    },
    overlayStyle() {
      return {
        position: 'fixed',
        display: this.overlayDisplay,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(22, 22, 29, 0.97)',
        zIndex: 50,
        textAlign: 'center',
        opacity: this.opacity,
        transition: 'opacity 0.3s ease-in-out',
        overflow: 'auto',
      };
    },
    query() {
      return this.$route.query;
    },
    isPersonalityTestActive() {
      const queryParams = this.$route.query;
      return queryParams.hasOwnProperty('personalityTest') && queryParams.personalityTest === 'true';
    },
  },
  watch: {
    async query() {
      await this.checkQuery();
    },
  },
  async mounted() {
    const shownOverlay = sessionStorage.getItem('shownOverlay');
    if (!shownOverlay) {
      const body = document.body;
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
      this.opacity = 1;
      this.overlayDisplay = 'inline';
    }
    await this.checkQuery();

    window.addEventListener('miros-search', (event) => this.checkQuery(event.detail));
  },
  beforeUnmount() {
    window.removeEventListener('miros-search', this.checkQuery);
  },
  methods: {
    addProductEntryToRecommendations(recommendations) {
      for (let i = 0; i < this.noOfItems; i++) {
        if (!recommendations[i]) break;
        recommendations[i].entry = {
          name: 'OnProductPLP',
          component: OnProductPLP,
          item: recommendations[i].alias,
        };
      }

      this.recommendations = Object.freeze(recommendations);

      this.loading = false;
    },
    async checkQuery(search) {
      await this.fetchArticles(search);
    },
    async fetchArticles(search) {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const articles = await getArticlesGraphQl({ pool_id: this.pool, search, lang: searchParams.get('lang') });
        this.addProductEntryToRecommendations(articles);
      } catch (error) {
        console.log(error);
      }
    },
    goToTutorialStep3(e) {
      e.stopPropagation();
      e.preventDefault();

      if (!this.pool && !this.$route.params.item) {
        if (window.SHOP_POOL) {
          window.showFF({ pool_id: window.SHOP_POOL });
        }
      } else {
        window.showFF({ pool: this.pool, item: this.item });
      }
    },
    closeOverlay() {
      sessionStorage.setItem('shownOverlay', 'true');
      const body = document.body;
      body.style.overflowY = 'scroll';
      this.opacity = 0;
      setTimeout(() => {
        this.overlayDisplay = 'none';
      }, 400);
    },
    async fetchRecommendationsNLP() {
      try {
        if (this.$route.query?.s) {
          this.loading = true;
          const recommendations = await getRecommendationsByNLP({
            text_query: this.$route.query?.s,
            amount: this.noOfItems,
            pool_id: this.pool,
          });

          this.addProductEntryToRecommendations(recommendations);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style module>
.overlayTextWrapper {
  padding-top: 124px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  line-height: 38px;
  display: inline-block;
  /*width: 320px;*/
  /*margin-left: -15px;*/
}

.overlayTextWrapper p {
  font-family: 'Inter Tight', sans-serif;
}

.personalize {
  background: #EFEFEF;
  width: 280px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /*padding: 16px 146px;*/
}

.personalize span {
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #16161D;
}

.title {
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #16161D;
  padding-top: 34px;
}

.personalised {
  color: #805CD2;
  display: flex;
  justify-content: center;
}

.loader {
  margin: 4rem auto 0;
  padding-top: 250px;
  padding-bottom: 200px;
}

.wrap {
  margin: 0 auto 124px;

  max-width: 1233px;
}

.image {
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  aspect-ratio: 1;
  align-items: center;
  text-align: center;
  background: #f3f3f4 no-repeat center;

  display: flex;
  justify-content: center;
}

.columns_mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 34px;
  grid-column-gap: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 44px;
  grid-column-gap: 24px;
}

.description {
  padding-top: 8px;
}

.description p {
  text-align: center;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8A8A8E;
}

.name {
  width: 200px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 36px;
}

.pagiTop {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagi svg {
  height: 100%;
}

.desktop .pagiTop {
  text-align: right;
  margin-right: 0;
}

.img {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 120%;
  position: relative;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .name {
    width: 100%;
  }
}
</style>
