<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
* {
  font-family: Montserrat;
}
#app {
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background: #fafafa;
}
</style>
