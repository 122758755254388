<template>
  <div :class="$style.layout">
    <header :class="$style.header" :style="headerStyle">
      <div v-if="!(mobile && showBanner)" @click="back" style="cursor: pointer">
          <svg width="102" height="20" viewBox="0 0 102 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_1997)">
                  <path d="M43.1872 0.736816H36.8936V19.2631H43.1872V0.736816Z" fill="#16161D"/>
                  <path d="M79.8878 5.01268C79.0349 3.57689 77.8239 2.47163 76.2549 1.69268C74.6858 0.915841 72.8498 0.526367 70.7491 0.526367C68.6483 0.526367 66.8123 0.915841 65.2432 1.69268C63.6742 2.46953 62.4632 3.57689 61.6103 5.01268C60.7574 6.44847 60.332 8.11163 60.332 10.0001C60.332 11.8885 60.7574 13.5537 61.6103 14.9874C62.4632 16.4232 63.6742 17.5306 65.2432 18.3074C66.8123 19.0843 68.6483 19.4737 70.7491 19.4737C72.8498 19.4737 74.6836 19.0864 76.2549 18.3074C77.8239 17.5306 79.0349 16.4232 79.8878 14.9874C80.7407 13.5516 81.1661 11.8885 81.1661 10.0001C81.1661 8.11163 80.7407 6.44637 79.8878 5.01268ZM73.7743 13.3937C73.0408 14.219 72.0338 14.6337 70.7512 14.6337C69.4686 14.6337 68.4595 14.2211 67.7281 13.3937C66.9946 12.5685 66.6278 11.4716 66.6278 10.1074C66.6278 8.74321 66.9946 7.64637 67.7281 6.8211C68.4616 5.99584 69.4686 5.5811 70.7512 5.5811C72.0338 5.5811 73.0408 5.99374 73.7743 6.8211C74.5079 7.64637 74.8746 8.74321 74.8746 10.1074C74.8746 11.4716 74.5079 12.5685 73.7743 13.3937Z" fill="#16161D"/>
                  <path d="M88.3951 13.0737C88.5166 13.7411 88.892 14.2779 89.5171 14.6822C90.1421 15.0885 90.9212 15.2906 91.8544 15.2906C92.8353 15.2906 93.5971 15.1601 94.1374 14.8969C94.6778 14.6358 94.9469 14.2295 94.9469 13.6822C94.9469 13.3495 94.8297 13.099 94.5975 12.9306C94.3631 12.7643 93.9964 12.6379 93.4929 12.5558C92.9894 12.4716 92.1126 12.3474 90.8604 12.1811C88.2345 11.8716 86.24 11.3348 84.8793 10.5727C83.5164 9.81058 82.8371 8.55795 82.8371 6.819C82.8371 4.91163 83.6162 3.38742 85.1745 2.24216C86.7327 1.099 88.8117 0.526367 91.4138 0.526367C94.1874 0.526367 96.4314 1.06952 98.1502 2.15374C99.8668 3.23795 100.776 4.70953 100.874 6.56847H94.91C94.8601 6.0211 94.5411 5.57268 93.953 5.22742C93.3648 4.88216 92.6031 4.70953 91.6699 4.70953C90.8105 4.70953 90.1356 4.84005 89.6451 5.10321C89.1546 5.36637 88.9094 5.74742 88.9094 6.24637C88.9094 6.65268 89.1546 6.93268 89.6451 7.08637C90.1356 7.24216 91.0319 7.379 92.3318 7.49689L93.0675 7.56847C95.6197 7.83163 97.6011 8.36216 99.0117 9.16005C100.422 9.95795 101.128 11.3222 101.128 13.2527C101.128 14.5158 100.748 15.6127 99.9862 16.5411C99.2244 17.4716 98.1523 18.1916 96.7656 18.7032C95.3788 19.2169 93.7663 19.4716 91.9238 19.4716C89.1742 19.4716 86.941 18.8948 85.2244 17.7369C83.5056 16.5811 82.5854 15.0253 82.4639 13.0716H88.3907L88.3951 13.0737Z" fill="#16161D"/>
                  <path d="M25.3916 0.736816H22.3533C20.4283 0.736816 18.6574 1.37892 17.2533 2.45261C15.8491 1.37892 14.0782 0.736816 12.1533 0.736816H9.11497C4.5597 0.736816 0.868164 4.31787 0.868164 8.73682V19.2631H7.16178V8.52629C7.16178 6.89892 8.5225 5.57892 10.2001 5.57892H11.0682C12.7457 5.57892 14.1065 6.89892 14.1065 8.52629V19.2631H20.4001V8.52629C20.4001 6.89892 21.7608 5.57892 23.4384 5.57892H24.3065C25.984 5.57892 27.3448 6.89892 27.3448 8.52629V19.2631H33.6384V8.73682C33.6384 4.31787 29.9468 0.736816 25.3916 0.736816Z" fill="#16161D"/>
                  <path d="M54.4724 0.736816C49.9171 0.736816 46.2256 4.31787 46.2256 8.73682V19.2631H52.5192V8.52629C52.5192 6.89892 53.8799 5.57892 55.5575 5.57892H59.4639V0.736816H54.4724Z" fill="#16161D"/>
              </g>
              <defs>
                  <clipPath id="clip0_1_1997">
                      <rect width="102" height="20" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
      </div>
      <div v-if="!showBanner" :class="$style.poweredText" style="cursor: pointer;" @click="setShowBanner(true)">
        <svg width="30" height="30" viewBox="0 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="9" width="22" height="2" fill="#16161D"/>
          <rect x="4" y="14" width="22" height="2" fill="#16161D"/>
          <rect x="4" y="19" width="22" height="2" fill="#16161D"/>
        </svg>
      </div>
    </header>
    <transition v-if="showBanner" name="slide-bottom" mode="out-in">
      <Menu @hide-banner="setShowBanner(false)"/>
    </transition>
    <div :style="{height: loading ? '100%' : ''}" @click="setShowBanner(false)">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import Menu from '@/layouts/Menu.vue';
import { isMobile } from '@/util';

export default {
  components: { Footer, Menu },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showBanner: false,
    };
  },
  computed: {
    mobile() {
      return isMobile();
    },
    headerStyle() {
      let justify = 'space-between';
      if (this.showBanner && this.mobile) {
        justify = 'end';
      }

      return {
        justifyContent: justify,
      };
    },
  },
  methods: {
    back() {
      this.$router.replace({ path: `/${this.$route.params.customer}` });
    },
    setShowBanner(condition) {
      this.showBanner = condition;
    },
  },
};
</script>

<style module>
.layout {
  height: 100%;
}

.header {
  display: flex;
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  padding: 17px 20px;
  z-index: 3;
}

.poweredText {
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #16161D;
}

.logos svg {
  width: 2.875rem;
  height: 1.1875rem;
  position: absolute;
  top: 0;
  left: 0;
}

.hideOverflow {
    overflow: hidden;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0 5px 0 5px;
}

.desktop {
  margin-top: 39px;
}
</style>

<style>
html {
  font-size: 12px;
}
/* until iPhone 5S */
@media screen and (min-width: 321px) {
  html {
    font-size: 14px;
  }
}

/* from iPhone X/6 */
@media screen and (min-width: 375px) {
  html {
    font-size: 16px;
  }
}

/* from Tablet */
@media screen and (min-width: 768px) {
  html {
    font-size: 20px;
  }
}

body,
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100% !important;
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbfbfb;
}

a {
  text-decoration: none;
  color: #16161d;
  line-height: 1;
}
h1 {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.6875rem;
  color: #16161d;
}
</style>
