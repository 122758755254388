<template>
  <div :style="style" :class="$style.overlayButton" @click="null">
    <img :style="{height: imageHeight+'px'}" style="margin-right: 10px" src="/miros-logo.png" alt="miros image"/>
    <span>{{text}}</span>
  </div>
</template>
<script>
export default {
  name: 'CTAButton',
  props: {
    text: {
      type: String,
      required: false,
    },
    style: Object,
    textStyle: Object,
    exploreBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdpBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageHeight: {
      type: String,
      required: true,
    },
  },
  methods: {
    openFF(e) {
      e.stopPropagation();
      e.preventDefault();

      if (!this.pool && !this.entry) {
        if (window.SHOP_POOL) {
          window.showFF({ pool_id: window.SHOP_POOL });
        }
      } else {
        window.showFF({
          pool: this.$route.params.pool,
          item: this.entry.item,
        });
      }
    },
  },
};
</script>
<style module>
.overlayButton{
  width: 280px;
  height: 40px;
  color: #16161D;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.overlayButton span{
  font-family: 'Inter Tight', sans-serif;
}
.cta {
  display: block;
  width: 17.625rem;
  height: 3.375rem;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.37125rem;
  font-weight: 700;
  position: relative;
  margin: 0 auto;
  border: 0;
  background: linear-gradient(90deg, #00e8ab -2.54%, #b9ffed 100%);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.cta_exploreBtn {
  display: block;
  width: 17.625rem;
  height: 3.375rem;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.37125rem;
  font-weight: 700;
  position: relative;
  margin: 0 auto;
  border: 0;
  background: linear-gradient(90deg, #00e8ab -2.54%, #b9ffed 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.cta span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}
.cta svg {
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
}

.text {
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #16161d;
}
</style>
