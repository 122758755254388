<template>
  <footer :class="$style.footer">
    <svg id="Layer_1" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 95"><defs></defs><rect fill="#fff" x="170" y="3.5" width="29" height="88"/><path fill="#fff" d="M368.11,23.81c-3.93-6.82-9.51-12.07-16.74-15.77-7.23-3.69-15.69-5.54-25.37-5.54s-18.14,1.85-25.37,5.54c-7.23,3.69-12.81,8.95-16.74,15.77-3.93,6.82-5.89,14.72-5.89,23.69s1.96,16.88,5.89,23.69c3.93,6.82,9.51,12.08,16.74,15.77,7.23,3.69,15.69,5.54,25.37,5.54s18.13-1.84,25.37-5.54c7.23-3.69,12.81-8.95,16.74-15.77,3.93-6.82,5.89-14.72,5.89-23.69s-1.96-16.88-5.89-23.69Zm-28.17,39.81c-3.38,3.92-8.02,5.89-13.93,5.89s-10.56-1.96-13.93-5.89c-3.38-3.92-5.07-9.13-5.07-15.61s1.69-11.69,5.07-15.61c3.38-3.92,8.02-5.89,13.93-5.89s10.55,1.96,13.93,5.89c3.38,3.92,5.07,9.13,5.07,15.61s-1.69,11.69-5.07,15.61Z"/><path fill="#fff" d="M407.31,62.1c.56,3.17,2.29,5.72,5.17,7.64,2.88,1.93,6.47,2.89,10.77,2.89,4.52,0,8.03-.62,10.52-1.87,2.49-1.24,3.73-3.17,3.73-5.77,0-1.58-.54-2.77-1.61-3.57-1.08-.79-2.77-1.39-5.09-1.78-2.32-.4-6.36-.99-12.13-1.78-12.1-1.47-21.29-4.02-27.56-7.64-6.28-3.62-9.41-9.57-9.41-17.83,0-9.06,3.59-16.3,10.77-21.74,7.18-5.43,16.76-8.15,28.75-8.15,12.78,0,23.12,2.58,31.04,7.73,7.91,5.15,12.1,12.14,12.55,20.97h-27.48c-.23-2.6-1.7-4.73-4.41-6.37-2.71-1.64-6.22-2.46-10.52-2.46-3.96,0-7.07,.62-9.33,1.87-2.26,1.25-3.39,3.06-3.39,5.43,0,1.93,1.13,3.26,3.39,3.99,2.26,.74,6.39,1.39,12.38,1.95l3.39,.34c11.76,1.25,20.89,3.77,27.39,7.56,6.5,3.79,9.75,10.27,9.75,19.44,0,6-1.75,11.21-5.26,15.62-3.51,4.42-8.45,7.84-14.84,10.27-6.39,2.44-13.82,3.65-22.31,3.65-12.67,0-22.96-2.74-30.87-8.24-7.92-5.49-12.16-12.88-12.72-22.16h27.31Z"/><path fill="#fff" d="M117,3.5h-14c-8.87,0-17.03,3.05-23.5,8.15-6.47-5.1-14.63-8.15-23.5-8.15h-14C21.01,3.5,4,20.51,4,41.5v50h29V40.5c0-7.73,6.27-14,14-14h4c7.73,0,14,6.27,14,14v51h29V40.5c0-7.73,6.27-14,14-14h4c7.73,0,14,6.27,14,14v51h29V41.5c0-20.99-17.01-38-38-38Z"/><path fill="#fff" d="M251,3.5c-20.99,0-38,17.01-38,38v50h29V40.5c0-7.73,6.27-14,14-14h18V3.5h-23Z"/></svg>
    <span :class="$style.itemCount">{{itemCount}}</span>
  </footer>
</template>

<script>
export default {
  computed: {
    itemCount() {
      return window.ITEM_COUNT;
    },
  },
};
</script>

<style module>
.footer {
  background: #16161d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 236px;
  position: relative;
}

.itemCount {
  position: absolute;
  font-size: 11px;
  color: white;
  bottom: 0;
  right: 10px;
}
</style>
