<template>
  <div :class="$style.header">
    <div :class="$style.header_back">
      <button :class="$style['header_back-button']" @click="goBack">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="1.7711"
            height="10.1464"
            rx="0.885552"
            transform="matrix(-0.715698 -0.69841 -0.715698 0.69841 23.5303 14.237)"
            fill="#16161D"
          />
          <rect
            width="1.77498"
            height="10.1464"
            rx="0.887491"
            transform="matrix(0.715698 -0.69841 -0.715698 -0.69841 22.2618 27.1735)"
            fill="#16161D"
          />
        </svg>
      </button>
    </div>
    <div :class="$style.header_logo">
      <svg
        width="131"
        height="22"
        viewBox="0 0 131 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.5553 6.91475L62.6371 6.52662H64.2223C65.2246 3.53398 67.8735 0 70.3486 0C71.2384 0 72.0668 0.541332 72.0668 1.49122C72.0668 2.31853 71.4838 2.88029 70.6759 2.88029C69.919 2.88029 69.3258 2.37982 69.3258 1.64442C69.3258 0.949884 69.6122 0.561759 70.277 0.326842V0.255345C69.152 0.255345 68.436 1.16437 67.5667 4.44301C67.4133 5.02519 67.1781 5.74016 67.0042 6.52662H68.8963L68.8145 6.91475H66.9224L65.7053 12.4608C65.378 13.9521 62.903 21.6737 59.7836 21.6737C59.0063 21.6737 57.9836 21.1937 57.9836 20.305C57.9836 19.3552 58.587 18.8955 59.3745 18.8955C60.3052 18.8955 60.7245 19.4982 60.7245 20.0906C60.7245 20.7647 60.2438 21.1732 59.7938 21.2447V21.2856C61.2052 21.2856 61.6143 20.1825 62.0337 17.8844C62.903 13.135 63.7519 8.17105 64.1405 6.91475H62.5553Z"
          fill="#16161D"
        />
        <path
          d="M77.2112 13.7886C77.0782 14.2483 76.8839 14.7692 76.8839 14.9632C76.8839 15.3309 77.0578 15.4228 77.2725 15.4228C77.968 15.4228 78.8783 14.1461 79.1135 13.6661L79.4203 13.8397C78.8169 14.9019 77.8146 16.1991 76.2089 16.1991C75.1248 16.1991 74.5418 15.2186 74.5418 13.9827H74.5009C74.0713 14.8713 72.9156 16.1991 71.4838 16.1991C69.786 16.1991 68.8553 14.7283 68.8553 12.7775C68.8553 10.2649 71.0747 6.27127 74.4089 6.27127C75.5134 6.27127 76.0759 6.83303 76.2089 7.87484H76.2498L76.6384 6.52662H79.3487L77.2112 13.7886ZM71.8418 11.6642C71.494 12.706 71.0645 14.6159 71.0645 14.9836C71.0645 15.6169 71.4531 15.811 71.8009 15.811C72.5168 15.811 73.7747 15.0756 74.5623 13.1043C75.2986 11.2658 75.9532 9.22306 75.9532 8.31403C75.9532 7.03731 75.2782 6.66961 74.7361 6.66961C74.0407 6.66961 73.4782 6.92495 72.5168 9.70311L71.8418 11.6642Z"
          fill="#16161D"
        />
        <path
          d="M83.8284 16.1991C82.4171 16.1991 80.5045 15.2901 80.5045 13.8193C80.5045 12.9307 80.9852 12.2974 81.8239 12.2974C82.5807 12.2974 83.1023 12.9 83.1023 13.615C83.1023 14.5445 82.4682 14.8509 81.9466 14.8917C81.7318 14.8917 81.5784 14.9326 81.5784 15.0449C81.5784 15.239 82.6625 15.9335 83.8591 15.9335C85.0148 15.9335 86.0989 15.525 86.0989 14.1359C86.0989 12.2668 82.3659 12.4711 82.3659 9.79504C82.3659 7.71142 84.258 6.26106 86.3342 6.26106C87.4183 6.26106 89.2183 6.76154 89.2183 8.17104C89.2183 8.99836 88.8092 9.64183 87.9399 9.64183C87.224 9.64183 86.7433 9.08007 86.7433 8.36511C86.7433 7.53779 87.3058 7.24159 87.674 7.17009C87.7865 7.14966 87.9092 7.10881 87.9092 7.01688C87.9092 6.8841 87.1012 6.51641 86.3035 6.51641C85.4342 6.51641 84.1762 6.99646 84.1762 8.16083C84.1762 10.2649 88.1751 9.70312 88.1751 12.992C88.1956 15.2186 85.8023 16.1991 83.8284 16.1991Z"
          fill="#16161D"
        />
        <path
          d="M93.9639 1.06224H92.706L92.7878 0.674111H93.9639C96.0503 0.674111 96.6333 0.347269 97.0731 0L94.4446 8.8043L94.4855 8.82473C95.3753 7.74206 96.5719 6.27128 98.2186 6.27128C99.5175 6.27128 100.233 6.98624 100.233 8.08933C100.233 8.74302 99.7731 9.97889 99.405 11.0411L98.4231 13.7989C98.2697 14.2585 98.0958 14.7794 98.0958 14.9734C98.0958 15.3411 98.2697 15.4331 98.4845 15.4331C99.1799 15.4331 100.09 14.1563 100.325 13.6763L100.632 13.8499C100.029 14.9122 99.0265 16.2093 97.4208 16.2093C96.2651 16.2093 95.6208 15.3616 95.6208 14.3913C95.6208 13.9827 95.8151 13.5027 95.9276 13.1554L97.6458 8.07912C97.7788 7.691 97.8811 7.40501 97.8811 7.12924C97.8811 6.84325 97.6254 6.80239 97.4208 6.80239C96.3776 6.80239 94.3628 9.27414 94.056 10.3159L92.4298 15.954H89.6479L93.9639 1.06224Z"
          fill="#16161D"
        />
        <path
          d="M103.127 6.52662H105.367C106.41 6.52662 106.932 6.35299 107.362 6.04658L104.999 13.7886C104.866 14.2483 104.672 14.7692 104.672 14.9632C104.672 15.3309 104.846 15.4229 105.06 15.4229C105.756 15.4229 106.666 14.1461 106.901 13.6661L107.208 13.8397C106.605 14.9019 105.603 16.1991 104.191 16.1991C102.197 16.1991 102.197 14.5751 102.197 14.4015C102.197 14.0542 102.33 13.5537 102.452 13.1247L104.385 6.92496H103.035L103.127 6.52662ZM106.922 1.36865C107.75 1.36865 108.415 2.04276 108.415 2.85987C108.415 3.68719 107.74 4.35108 106.922 4.35108C106.104 4.35108 105.429 3.67697 105.429 2.85987C105.429 2.04276 106.093 1.36865 106.922 1.36865Z"
          fill="#16161D"
        />
        <path
          d="M112.394 16.1991C110.614 16.1991 108.875 14.8815 108.875 12.4915C108.875 8.70216 112.005 6.27127 114.521 6.27127C116.843 6.27127 118.172 8.04847 118.172 10.2342C118.172 12.9613 115.851 16.1991 112.394 16.1991ZM114.695 6.52662C113.478 6.52662 113.069 7.56842 112.741 8.39574C111.964 10.3874 111.095 13.4209 111.095 14.5138C111.095 15.4637 111.596 15.9437 112.373 15.9437C113.693 15.9437 114.091 14.7896 114.419 13.9725C114.92 12.7366 115.963 8.50809 115.963 7.75227C115.953 6.89431 115.605 6.52662 114.695 6.52662Z"
          fill="#16161D"
        />
        <path
          d="M121.752 6.91474H120.494L120.576 6.52662H122.816C123.859 6.52662 124.38 6.35298 124.81 6.04657L123.92 8.8043L123.961 8.82473C124.851 7.74206 126.047 6.27127 127.694 6.27127C128.993 6.27127 129.709 6.98624 129.709 8.08933C129.709 8.74301 129.249 9.97889 128.88 11.0411L127.899 13.7988C127.745 14.2585 127.571 14.7794 127.571 14.9734C127.571 15.3411 127.745 15.4331 127.96 15.4331C128.655 15.4331 129.566 14.1563 129.801 13.6763L130.108 13.8499C129.504 14.9122 128.502 16.2093 126.896 16.2093C125.741 16.2093 125.096 15.3616 125.096 14.3912C125.096 13.9827 125.291 13.5026 125.403 13.1554L127.121 8.07912C127.254 7.69099 127.357 7.40501 127.357 7.12923C127.357 6.84325 127.101 6.80239 126.896 6.80239C125.853 6.80239 123.838 9.27413 123.532 10.3159L121.905 15.954H119.123L121.752 6.91474Z"
          fill="#16161D"
        />
        <path
          d="M0.0246582 15.525H1.41559V1.08265H0.0246582V0.674103H12.134V4.61663H11.7249C11.7249 3.5544 10.9169 1.08265 8.20662 1.08265H5.84408V7.80334H6.69296C8.68731 7.82377 9.6896 7.20073 9.6896 5.07625H10.0987V10.9696H9.6896C9.6896 9.58054 9.0555 8.28339 7.50093 8.21189H5.84408V15.5148H7.23501V15.9233H0.0246582V15.525Z"
          fill="#16161D"
        />
        <path
          d="M13.0541 15.525H14.4451V1.08265H13.0541V0.674103H20.2645V1.08265H18.8736V15.525H20.2645V15.9335H13.0541V15.525Z"
          fill="#16161D"
        />
        <path
          d="M21.2158 15.525C22.3409 15.525 23.0159 15.3513 23.0159 13.9827V2.93135L21.7374 1.09287H21.1749V0.684317H26.6671L34.2252 11.6539H34.2661V2.51259C34.2661 1.95083 34.1127 1.08265 32.6808 1.08265V0.674103H36.24V1.08265C34.9615 1.08265 34.8081 1.95083 34.8081 2.51259V15.9335H32.0774L23.5988 3.66675H23.5579V13.9827C23.5579 15.3718 23.9875 15.525 25.225 15.525V15.9335H21.2056V15.525H21.2158Z"
          fill="#16161D"
        />
        <path
          d="M37.0172 15.525H38.4081V1.08265H37.0172V0.674103H44.7696C49.5253 0.674103 52.6549 3.72803 52.6549 8.24253C52.6549 13.4209 48.8094 15.9335 44.473 15.9335H37.0274V15.525H37.0172ZM42.8263 15.525H43.5627C47.7764 15.525 47.9503 11.511 47.9503 8.32424C47.9503 3.92209 47.3878 1.08265 43.6957 1.08265H42.8263V15.525Z"
          fill="#16161D"
        />
        <path
          d="M56.746 12.2055C57.8506 12.2055 58.7608 13.1145 58.7608 14.2176C58.7608 15.3207 57.8506 16.2297 56.746 16.2297C55.6414 16.2297 54.7312 15.3207 54.7312 14.2176C54.7312 13.1145 55.6414 12.2055 56.746 12.2055Z"
          fill="#16161D"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style module>
.header {
  position: relative;
  height: 4.375rem;
}
.header_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}
.header_back {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
}
</style>
